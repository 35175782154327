<template>
    <div>
        <h1>الطلبات الراجعة</h1>
    </div>
</template>

<script>
export default {};
</script>

<style>
</style>
